<template>
  <div class="container portfolio">
    <div class="portfolio-header scroll-from-bottom">
      <h1>Porfolio</h1>
      <h1>Our Projects</h1>
    </div>
	<div class="portfolio-body">
		<div class="portfolio-card__wrapper has-space scroll-from-top">
			<div class="portfolio-card" v-for="(o,index) in projectList" :key="index">
				<router-link :to="`/${o.urlName}`" v-if="o.name !== ''">
					<div class="portfolio-card__cover"></div>
					<img class="w-100 h-100 portfolio-card__img" :src="require(`@/assets/img/portfolio/${o.info.coverImg}`)">
					<div class="portfolio-card__info-wrapper">
						<div class="portfolio-card__info-container">
							<div class="portfolio-card__info-line"></div>
							<div class="portfolio-card__info-name">{{ o.info.name }}</div>
							<div class="portfolio-card__info-line"></div>
						</div>
						<div class="portfolio-card__info-btn">Explore</div>
					</div>
				</router-link>
				<div v-else>
					<div class="portfolio-card__cover"></div>
					<img class="portfolio-card__img" src="@/assets/img/portfolio/portfolio_comming_soon.png">
					<div class="portfolio-card__info-wrapper">
						<div class="portfolio-card__info-container">
							<div class="portfolio-card__info-line"></div>
							<div class="portfolio-card__info-name">
								<p>MORE</p>
								<p>COMMING</p>
								<p>SOON</p>
							</div>
							<div class="portfolio-card__info-line"></div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
  </div>
</template>

<script>
import config  from '@/js/config.js'

export default {
   data() {
        return {
            projectList: config.projectList
        }
    },
	mounted() {
		this.handleProjectList();
		
         window.sr = ScrollReveal();
	    this.$nextTick(() => {
      		sr.reveal('.scroll-from-left', {
				origin: 'left' ,
				duration: 1000,
				easing: 'ease-out' ,
				distance: '500px',
				delay: 100,
				         
			});
			    sr.reveal('.scroll-from-right', {
                    origin: 'right' ,
                    duration: 1000,
                    easing: 'ease-out' ,
                    distance: '200px',
                    delay: 100     ,
					     
                });
                sr.reveal('.scroll-from-bottom', {    
                    duration: 1000,
                    distance: '50px',
                    delay: 100              
                });
                sr.reveal('.scroll-from-top', {    
                    origin: 'top' ,
                    duration: 1000,
                    distance: '200px',
                    delay: 100              
                });
				  sr.reveal('.scroll-from-opacity', {    
                       distance: '200px',
						opacity: 0.8,
						duration: 1000,
                });
				 sr.reveal('.scroll-from-scaleUp', {    
                       distance: '200px',
   				scale: 0.85,
                duration: 1000,
                });
      	    });
	},
	methods: {
		handleProjectList() {
			const maxProjectList = 8;
			
			const obj = {
				imgSrc: '',
				name: ''
			}

			let currentProjectListLength = maxProjectList - this.projectList.length;

			for(let i = 0; i < currentProjectListLength; i++) {
				this.projectList.push(obj)
			}
			
		}
	},
}
</script>

<style>
	.portfolio{
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	.portfolio-header{
		margin: 7.25rem 0;
		text-align: center;
	}
	.portfolio-header h1{
		margin: 0;
	}
	.portfolio-body{
		margin-bottom: 10rem;
	}
	/* card */
	.portfolio-card__wrapper{
		display: grid;
		grid-template-columns: repeat(2,1fr);
		grid-column-gap: 4.25rem;
		grid-row-gap: 6rem;
	}
	.portfolio-card{
		position: relative;
		border-radius: 1rem;
    	overflow: hidden;
		cursor: pointer;
		height: 26.625rem;
	}
	.portfolio-card__cover{
		position: absolute;
		background-color: rgb(11,33,73,0.3);
		width: 100%;
		height: 100%;
	}
	.portfolio-card__info-wrapper{
		position: absolute;
		color: white;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	.portfolio-card__info-line{
		width: 3.5rem;
		height: 0.25rem;
		background-color: var(--color-theme);
	}
	.portfolio-card__info-container{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		row-gap: 1rem;
		margin-bottom: 3rem;
	}
	.portfolio-card__info-btn{
		background-color: white;
		color: var(--color-theme);
		border: 1px solid var(--color-theme);
		padding: .8rem 1.8rem;
		transition: all .3s;
	}
	.portfolio-card__info-btn:hover{
		background-color: var(--color-theme);
		color: white;
	}
	.portfolio-card__info-name{
		font-size: 1.5rem;
		text-align: center;
	}
	.portfolio-card__info-name p{
		margin: 0;
	}

    @media (max-width: 768px) {
         .portfolio-card__wrapper{
            display: flex;
            flex-direction: column;
            grid-row-gap: 2rem;
         }
         .portfolio-header{
            margin: 1.3rem 0;
         }
         .portfolio-card__img{
            width: 100%;
            object-fit: cover;
         }
         .portfolio-card{
            height: 55vw;
         }
         .portfolio-card__info-name{
            font-size: 1rem;
         }
         .portfolio-card__info-btn{
            font-size: .75rem;
         }
         .portfolio-card__info-container{
            margin-bottom: 1.5rem;
         }
    }
   
</style>